import {Route, Routes} from "react-router-dom";
import React, {Suspense} from 'react';
import {PageMainAsync} from "./Components/PageMain/PageMain.async";
import {PageMerchAsync} from "./Components/PageMerch/PageMerch.async";
import {PageProjectsAsync} from "./Components/PageProjects/PageProjects.async";
import {HeaderAsync} from "./Components/Header/Header.async";
import ButtonScrollUp from "./Components/ButtonScrollUp/ButtonScrollUp";

function App() {
    return (
        <div className="App">
            <HeaderAsync />
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route exact path="/" element={<PageMainAsync image="main"/>}/>
                    <Route path="/merch" element={<PageMerchAsync image="merch"/>}/>
                    <Route path="/projects" element={<PageProjectsAsync image="projects"/>}/>
                </Routes>
            </Suspense>
            <ButtonScrollUp />
        </div>
    );
}

export default App;
